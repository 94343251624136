:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --Bundle_listingImageMarginBottom: 12px;
  --Bundle_listingImageHeight: 100px;
}

.root {
  position: relative;
}

.listingImages {
  overflow-x: auto;
  padding: 16px 0 calc(16px - var(--Bundle_listingImageMarginBottom));

  @media (--viewportMedium) {
    flex-wrap: wrap;
    overflow-x: unset;
  }
}
