.dialogHeader {
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 2;
}

.formWrapper {
  height: 100%;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: solid 1px var(--gray20);
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  border-top: solid 1px var(--gray20);
  width: 100%;

  @media (--viewportMedium) {
    position: sticky;
  }
}

.paneBody {
  margin-bottom: 90px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.categoriesHeader {
  margin-top: 16px !important;
}

.freeformTextArea {
  padding: 2px 10px;
  min-height: 140px;
  border: 2px solid var(--gray20);
}

.smallIcon {
  height: 24px;
}
