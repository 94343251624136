.root {
  & p {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  & h2 {
    margin: 24px 0;
  }

  & li {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  & .list {
    list-style: lower-roman;
    padding-left: 32px;
  }
}
