.contentDesktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    text-decoration: none;
  }
}

.contentMobile {
  display: flex;
  align-items: center;
}

.avatar {
  flex-shrink: 0;
  margin: 2px;
  margin-right: 28px;
  border: 2px solid var(--gray80);
  height: 26px; /* 32px - (2px * 2) for border on both sides */
  width: 26px; /* 32px - (2px * 2) for border on both sides */

  @media (--viewportMedium) {
    margin-right: 24px;
  }
}

.showMore {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  text-decoration: none;
}

.verifiedIcon {
  margin-left: 8px;
}

.otherListingsArrowIcon {
  margin-left: 4px;
}
