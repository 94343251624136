.bundleTitle {
  padding: 12px;
}

.removeListingButton {
  border: none;
  cursor: pointer;
  padding-left: 8px;
  padding-right: unset;

  @media (--viewportLarge) {
    padding-left: 12px;
  }
}

.removeIcon {
  color: var(--gray60);
  width: 20px;

  &:hover {
    color: var(--gray80);
  }

  @media (--viewportLarge) {
    width: 24px;
  }
}
