.divider {
  background-color: var(--gray20);
}

.trashIcon {
  cursor: pointer;
  height: 22px;
  width: 22px;
  margin-bottom: 2px;
  margin-right: 8px;
}

.trashIconRemoveItems {
  height: 60px;
  width: 60px;
  margin-bottom: 20px;
}

.addMoreButton {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--gray20);
  background-color: var(--gray10);
  cursor: pointer;
  height: 100px;
  width: 100px;
  margin-bottom: 12px;

  &:hover {
    border-color: var(--gray40);
    text-decoration: none;
  }
}

.addMoreText {
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 13px;
  padding: 8px;
  text-align: center;
}

.dialogHeader {
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: var(--zIndexModal);
}

.headerBar {
  height: 60px;
}

.deleteBundleFooter {
  background-color: var(--matterColorLight);
  position: absolute;
  bottom: 0;
  padding: 24px;
  width: 100%;
}
