.root {
  & p {
    margin-bottom: 12px;
    margin-top: 12px;
  }

  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }
}
