.dialogHeader {
  padding: 0 !important; /* Override default MuiDialogTitle styles */
}

.formRoot {
  margin: 24px;

  @media (--viewportMedium) {
    margin: 36px;
  }
}
