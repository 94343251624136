/* ModalInMobile is visible by default */
.root {
  width: 100%;
  background-color: white;
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: none;
}

.modalContainer {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 0;
    height: 100%;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

.modalContent {
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
}
