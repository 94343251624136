.formRoot {
  margin: 24px;

  @media (--viewportMedium) {
    margin: 36px;
  }
}

.dialogHeader {
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 2;
}

.formWrapper {
  height: 100%;
}

.header {
  position: fixed;
  top: 0;
  z-index: 1;
  border-bottom: solid 1px var(--gray20);
  width: 100%;

  @media (--viewportMedium) {
    position: sticky;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  border-top: solid 1px var(--gray20);
  width: 100%;

  @media (--viewportMedium) {
    position: sticky;
  }
}

.productImage {
  padding: 48px;
  max-height: 400px;
  max-width: 400px;
}

.sizeNotificationsWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding: 48px;
  }
}
