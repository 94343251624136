/* ================ Custom properties aka variables ================ */

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /**
   * Font variables are separated to different file so that the amount of font styling rules
   * doesn't overflow this one.
   * see ./marketplaceFonts.css
   */

  /* ================ Colors ================ */

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %232f376a;
  --successColor: #68b37f;
  --successColorDark: #5da172;
  --successColorLight: #77ba8b;
  --failColor: #bb4e4e;
  --failColorLight: #fff0f0;
  --attentionColor: #fa0;
  --attentionColorLight: #fff7f0;
  --inputAttentionColor: var(--inputAttentionColor);
  --inputSuccessColor: var(--inputSuccessColor);
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);
  --priceColor: #cc3a3a;
  --matterColorDark: #000;
  --matterColor: var(--matterColor);
  --matterColorAnti: var(--matterColorAnti);
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #fff;
  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Content widths ================ */

  --contentMaxWidth: 1260px;

  /* ================ Spacing units ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;

  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;

  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;

  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadiusMobileSearch: 3px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar heights ================ */

  /* Keep in sync with SectionImages.js */
  --topbarHeight: 60px;
  --topbarHeightDesktop: 84px;

  /* ================ Modal default padding ================ */

  --modalPadding: 48px 24px;
  --modalPaddingMedium: 55px 60px;

  /* ================ Listing icon padding ================ */

  --listingIconPadding: 8px;

  /* ================ Listing card styles ================ */

  --listingCardButtonPadding: 8px;

  /* ================ Icons ================ */

  --marketplacebrandDirectIconMinSize: 60px;
  --marketplacebrandDirectIconMaxSize: 90px;
  --marketplacebrandDirectIconSize: 5vw;
  --SectionHero_desktopTitleMaxWidth: 750px;
}

/* ================ Custom media queries ================ */

/* Keep in sync with uploadcare.css */
@custom-media --viewportSmall (min-width: 550px);
@custom-media --viewportMedium (min-width: 768px);
@custom-media --viewportLarge (min-width: 1024px);
@custom-media --viewportLargeWithPaddings (min-width: 1128px);
@custom-media --viewportXLarge (min-width: 1921px);
