:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ManageBundlesContainer_mobileTopBarHeight: 60px;
}

.error {
  margin: 20px;
  color: var(--failColor);
}

/**********
 * MOBILE *
 **********/

.empty {
  margin-top: 60px;
  width: 100%;
}

.emptyButton {
  width: 100%;
}

.pageTitle {
  margin: 20px;
}

.rightArrowIcon {
  height: 16px;
  width: 16px;
}

.bundleTitle {
  & h4 {
    margin: 0;
  }
}

.individualBundleContainerContent {
  margin-top: var(--ManageBundlesContainer_mobileTopBarHeight);
  padding-bottom: 64px;

  @media (--viewportMedium) {
    padding-bottom: 0;
  }
}

/***********
 * DESKTOP *
 ***********/

.body {
  overflow-y: auto;

  @media (--viewportLarge) {
    height: unset;
  }
}

.tab {
  & h3,
  & h4 {
    margin: 0;
  }
}

.content {
  max-width: 500px;

  & h2 {
    margin: 0;
  }
}

.sideNav {
  max-height: 790px;
  padding: 44px 0 44px max(calc((100% - 1056px) / 2), 16px);
}

/*****************
 * MOBILE TOPBAR *
 *****************/

.topBar {
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--ManageBundlesContainer_mobileTopBarHeight);
  border-bottom: 1px solid var(--gray40);
  background-color: var(--matterColorLight);
  z-index: var(--zIndexTopbar);
}

.backIcon {
  width: 32px;
  height: 18px;
}

/**************************
 * THREE DOTS ICON BUTTON *
 **************************/

.threeDotIconWrapper {
  padding: 0;
}

.threeDotIcon {
  width: 32px;
  height: 32px;
}

.menuContent {
  width: 250px;
  position: absolute;
  right: 0;
  z-index: var(--zIndexPopup);
  border-radius: var(--borderRadiusHalf);
  box-shadow: var(--boxShadowPopup);
}

.menuItem {
  border: 1px solid var(--gray20);
  padding: 16px;
  width: 100%;

  &:last-child {
    border-top: none;
  }
}

.menuItemText {
  width: 100%;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
}

.cancelOrder {
  color: var(--red60);
  text-align: left;
}

/*******************************
 * INDIVIDUAL BUNDLE CONTAINER *
 *******************************/

.potentialPayoutText {
  padding: 20px;
  text-align: center;
}

/**********
 * SEARCH *
 **********/
.search {
  background-color: white;
  margin-bottom: 16px;
}
