.listingPanel {
  width: 100%;
  max-width: var(--contentMaxWidth);
  margin: 24px auto 0 auto;

  /* This is needed to offset the grid spacing */
  padding: 0 24px;

  @media (--viewportMedium) {
    margin: 8px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 8px auto 0 auto;
  }
}

.title {
  @media (--viewportLarge) {
    margin: 24px 36px;
  }
}

.listingImages {
  overflow-x: hidden;
}

.listingPreview {
  border: 1px solid var(--gray10);
  margin-bottom: 0;
  margin-right: 0;
  height: 68px;
}

.listingPreviewGradient {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 90%, var(--gray10));
  z-index: 1;
}

.banner {
  box-shadow: var(--boxShadow);
}

.infoCard {
  flex: 1;

  &:hover {
    text-decoration: none;
  }
}

.emptyStateIcon {
  width: 36px;
  height: 33px;
}

.infoCardsArrow {
  width: 20px;
  height: 20px;
}

.infoCardsIcon {
  width: 100px;
  height: 100px;
}
