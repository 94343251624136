.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.content {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);
  padding-top: 24px;
  border-radius: var(--borderRadius);
  border-bottom: none;

  @media (--viewportMedium) {
    flex-basis: 480px;
    flex-grow: 0;
    padding: var(--modalPaddingMedium);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }
}

.backgroundWrapper {
  display: flex;
  flex-grow: 1;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
    min-height: 1280px;
    background-image: var(--backgroundImageUrl);

    /* Add loading color for the div */
    background-color: var(--matterColor);

    /* Cover the whole screen with the background image */
    background-size: cover;

    /* Align the image within the container */
    background-position: center center;
  }
}
