.root {
  /* If they have provided a small background image use that, or else use the normal one */
  background-image: var(--footerImageUrlSmall);

  /* Add loading color for the div */
  background-color: var(--matterColor);

  /* Cover the whole screen with the background image */
  background-size: cover;

  /* Align the image within the container */
  background-position: center center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding: 32px 0;

  @media (--viewportMedium) {
    background-image: var(--footerImageUrl);
    padding: 83px 0;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }

  /* Text positioning is done with paddings */
}

.rootTint {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background-image:
    linear-gradient(-45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
    var(--footerImageUrlSmall);

  @media (--viewportMedium) {
    background-image:
      linear-gradient(-45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
      var(--footerImageUrl);
  }
}

.actions {
  min-width: 100%;

  @media (--viewportMedium) {
    min-width: 640px;
  }

  @media (--viewportLarge) {
    min-width: 720px;
  }
}
