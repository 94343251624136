.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);
  position: relative;
  align-items: center;

  @media (--viewportMedium) {
    padding: 48px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
    align-items: stretch;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px 0 82px calc((100% - 1056px) / 2);
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;

  @media (--viewportMedium) {
    height: 100%;
    flex-direction: column;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportMedium) {
    margin-left: 0;
  }
}
