.topbarLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoLink {
  flex-shrink: 0;
}

.logo {
  /* Good aspect ratio should be less than 160/27 (width/height)
     NOTE: extremely wide logos need special handling */

  max-height: 36px;
  max-width: 128px;
  object-fit: contain;
  object-position: left center;
  width: auto;

  @media (--viewportLarge) {
    max-height: 42px;
    max-width: 220px;
  }
}

.fullHeightLogo {
  height: auto;
  max-height: 40px;
  max-width: 128px;
  object-fit: contain;

  @media (--viewportLarge) {
    max-height: 64px;
    max-width: 220px;
  }
}

.poweredByIcon {
  height: 9px;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.hideTopbarLogo {
  display: none;
}
