.itemizedListing {
  & h4 {
    margin: 0;
  }
}

.listingImage {
  align-self: center;
  justify-self: center;
  border: 2px solid var(--gray10);
  max-height: 80px;
  max-width: 80px;
  object-fit: contain;
  width: auto;
}

.listingPrice {
  align-self: center;
  justify-self: flex-end;
  text-align: end;
}

.disabled {
  opacity: 0.5;
}

.icon {
  width: 24px;
  height: 24px;
}
