.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 80px;
  padding: 8px;
}

.noImageIcon {
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  margin-top: 5px;
  color: var(--matterColor);
  margin-bottom: 0;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
