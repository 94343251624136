.root {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 16px 0 0;
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    letter-spacing: -0.9px;
    margin: 0;
  }
}
