.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 100%;
}

.email {
  /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.bottomWrapper {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  margin-top: -24px;
  padding-bottom: 8px;
}

/* Helper links */
.modalHelperLink {
  color: var(--matterColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
