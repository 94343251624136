.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  height: 100%;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.bottomWrapper {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}
