.password {
  /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
