.actionButton {
  background-color: var(--successColor);
  color: white;
  width: 100%;

  @media (--viewportMedium) {
    width: auto;
  }
}

.logo {
  height: 30px;
  width: auto;
  max-width: 220px;
  margin: 10px;
}

.logoDisabled {
  opacity: 0.4;
}

.noStripeAccountWarning {
  /* Override the disabled text color */
  color: var(--matterColor);
}

.payoutLink {
  /* Need this to override the disabled card parent */
  pointer-events: auto;
}
