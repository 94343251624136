.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--gray80);
  border-bottom-width: 1px;
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }

  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--gray80);
}

.selectError {
  border-bottom-color: var(--failColor);
}
