/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.tab {
  margin: 0;
}

.treetLogo {
  display: inline;
  width: auto;
  max-height: 55%;
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

/* ================ Social logins & SSO ================ */

.socialButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 10px 0;
  text-align: center;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0 0;
  }
}

.socialButtonsOrText {
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.termsText {
  text-align: start;
  margin: 0;
}

.termsLink {
  color: var(--matterColor);
  margin: 0;

  &:hover {
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.termsWrapper {
  margin: 20px 0;
}
