@keyframes increaseOpacity {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes decreaseOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
}

.sizes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
  margin: unset;
  padding: unset;
}

.hidden {
  display: none !important;
}

.label {
  padding: 0;

  & label {
    padding: 0;
    margin: 0;
  }
}

.sizeBox {
  border: 1px solid var(--gray40);
  box-shadow: var(--boxShadowLight);
  cursor: pointer;
  padding: 8px 16px;
  margin: 4px;

  &:not(.sizeBoxDisabled) {
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-name: increaseOpacity;

    &:hover {
      box-shadow: var(--boxShadowButton);
    }
  }
}

.sizeBoxSelected {
  background-color: var(--gray80);
}

.sizeBoxDisabled {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-name: decreaseOpacity;
  cursor: default;
  opacity: 0.3;
}

.iconNotify {
  height: 120px;
  width: 120px;

  &:not(.iconNotifyDisabled) {
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-name: increaseOpacity;
  }
}

.iconNotifyDisabled {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-name: decreaseOpacity;
  opacity: 0.3;
}

.notify {
  background-color: var(--gray10);
  border-radius: 12px;
  cursor: pointer;
}
