/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (--viewportMedium) {
    margin-top: 10vh;
  }
}

.number {
  font-weight: var(--heroFontWeight);
  font-size: 42px;
  line-height: 42px;
  margin-top: 25px;
  margin-bottom: 20px;
  text-transform: var(--heroFontTextTransform);
  text-align: center;
  color: var(--marketplaceColor);

  @media (min-width: 1681px) {
    font-size: var(--heroFontSizeLarge);
    line-height: 96px;
  }

  @media (--viewportMedium) {
    font-size: var(--heroFontSizeDesktop);
    line-height: 72px;
    margin: 0;
  }
}

.heading {
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin-top: 16px;
  font-weight: var(--fontWeightBold);
  text-align: center;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    line-height: 40px;
    letter-spacing: -0.9px;
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
  }
}
