.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.header {
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--zIndexTopbar);
}

.content {
  flex-grow: 1;
  overflow-y: auto;
}

.empty {
  padding: 60px 0;

  @media (--viewportMedium) {
    padding: 60px;
  }
}

.headerBar {
  height: 60px;
}

.shopButton {
  width: 100%;
  text-decoration: none;
}

.editButton {
  cursor: pointer;
  justify-content: flex-end;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.divider {
  background-color: var(--gray20);
}

.continueFooter {
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  position: sticky;
  bottom: 0;
  width: 100%;
}
