:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --Topbar_logoHeight: 22px;
}

@keyframes slideUpAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
    bottom: -800px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    bottom: 0;
  }
}

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: none;
  }
}

.transparentTopbarBackground {
  background-color: #0000;
  box-shadow: none;
}

.transparentTopbarForeground {
  filter: brightness(0) invert(1);
}

.logoutButton {
  margin: 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);
  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Icon component styles */

.rootSearchIcon {
  stroke: var(--matterColor);
}

/* ================ Modal ================ */

.modalContainer {
  flex-grow: 1;

  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 0;
    height: 100%;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

.mobileShoppingBagContainer {
  height: 100%;
  width: 100vw;
}

.mobileShoppingBag {
  animation: slideUpAnimation ease-out 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  min-height: 100vh;
  opacity: 1;
  position: relative;
  visibility: hidden;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightBold);
}

.helperLink {
  color: var(--matterColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.helperText {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.bottomWrapper {
  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;
  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusHalf);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: var(--borderRadiusHalf);
  background-color: var(--marketplaceColorLight);
}

.genericErrorText {
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}
