@keyframes increaseOpacity {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOutBackground {
  0% {
    background-color: var(--gray40);
  }

  100% {
    background-color: inherit;
  }
}

.header {
  padding: 0 !important;
}

.subscribeEmailCloseButton {
  color: white !important;
  position: absolute !important;
  top: 0;
  right: 0;
}

.subscribeEmailBackground {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.subscribeEmailButton {
  height: 48px;
  margin: 0;
  width: 100%;

  /* Setting padding as 0 so it can assume whatever the height is of the inputs */
  padding: 0;
}

.subscribeEmailSpinner {
  stroke: white;
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.subscribeSizesBackground {
  animation: fadeOutBackground ease-in-out 1.5s;
}

.subscribeSizesContent {
  animation: increaseOpacity ease-in-out 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 1;
  position: relative;
  visibility: hidden;
}

.divider {
  background-color: var(--gray20);
}

.sizeNotificationsWrapper {
  @media (--viewportMedium) {
    padding: 48px;
  }
}
