.root {
  height: 16px;
  width: auto;
  fill: var(--socialIconsColor);
  transition: var(--transitionStyleButton);

  &:hover {
    fill: var(--socialIconsColorHover);
  }
}
