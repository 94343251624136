.root {
  & h4 {
    margin: 0;
  }
}

.divider {
  background-color: var(--gray20);
}

.externalLink:hover {
  text-decoration: none;
}
